import { EpicIcons } from "@thekeytechnology/epic-ui";
import { graphql, useMutation } from "react-relay";
import { Button } from "@components/button";
import { DeleteProductButton_DeleteMutation } from "@relay/DeleteProductButton_DeleteMutation.graphql";

const DELETE_PRODUCT_MUTATION = graphql`
	mutation DeleteProductButton_DeleteMutation($input: DeleteProductInput!, $connections: [ID!]!) {
		Admin {
			Billing {
				deleteProduct(input: $input) {
					productId @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;

type OwnProps = {
	id: string;
	connectionId: string;
};

export const DeleteProductButton = ({ connectionId, id }: OwnProps) => {
	const [deleteProduct, isDeleting] =
		useMutation<DeleteProductButton_DeleteMutation>(DELETE_PRODUCT_MUTATION);

	return (
		<Button
			icon={EpicIcons.TRASH}
			tooltip="Produkt löschen"
			loading={isDeleting}
			disabled={isDeleting}
			onClick={() => {
				deleteProduct({
					variables: {
						input: {
							productId: id,
						},
						connections: [connectionId],
					},
				});
			}}
		/>
	);
};
