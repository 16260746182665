import { ButtonType } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { useFragment, useMutation } from "react-relay";
import { Button, ButtonVariant } from "@components/button";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { textWithPagesElementForm_EditTextWithPagesElementMutation } from "@relay/textWithPagesElementForm_EditTextWithPagesElementMutation.graphql";
import { textWithPagesElementForm_TextWithPagesElementFragment$key } from "@relay/textWithPagesElementForm_TextWithPagesElementFragment.graphql";
import { TextWithPagesElementFormSchema } from "@screens/educational-offer/parts/text-with-pages-element-form/text-with-pages-element-form.consts";
import {
	EDIT_TEXT_WITH_PAGES_ELEMENT_MUTATION,
	TEXT_WITH_PAGES_ELEMENT_FRAGMENT,
} from "@screens/educational-offer/parts/text-with-pages-element-form/text-with-pages-element-form.graphql";
import { backButtonClass } from "@screens/educational-offer/parts/text-with-pages-element-form/text-with-pages-element-form.styles";
import {
	TextWithPagesElementFormProps,
	TextWithPagesElementFormState,
} from "@screens/educational-offer/parts/text-with-pages-element-form/text-with-pages-element-form.types";
import { TextWithPagesElementPagesEditor } from "@screens/educational-offer/parts/text-with-pages-element-pages-editor/text-with-pages-element-pages-editor.component";
import { addEditedFormToEditedFormsArray, resetArrayOfEditedForms } from "@store/slices/CoreSlice";
import { useTypedDispatch } from "@store/store.redux";

export const TextWithPagesElementForm = ({
	textWithPagesElementFragmentRef,
	onBack,
}: TextWithPagesElementFormProps) => {
	const toast = useRef<Toast>(null);

	const element = useFragment<textWithPagesElementForm_TextWithPagesElementFragment$key>(
		TEXT_WITH_PAGES_ELEMENT_FRAGMENT,
		textWithPagesElementFragmentRef,
	);
	const [editTextWithPagesElement, isEditingTextWithPagesElement] =
		useMutation<textWithPagesElementForm_EditTextWithPagesElementMutation>(
			EDIT_TEXT_WITH_PAGES_ELEMENT_MUTATION,
		);

	const dispatch = useTypedDispatch();
	const formId = "TextWithPagesElementForm";

	const formik = useFormik<TextWithPagesElementFormState>({
		initialValues: {
			title: element.title,
		},
		validationSchema: TextWithPagesElementFormSchema,
		onSubmit: (values, { setSubmitting }) => {
			editTextWithPagesElement({
				variables: {
					input: {
						elementId: element.id,
						newTitle: values.title,
						newTask: "Unused",
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
					setSubmitting(false);
				},
				onError: () => {
					toast.current?.show({
						severity: "error",
						life: 3000,
						summary: "Fehler beim Speichern",
						detail: "Der Baustein konnte nicht gespeichert werden.",
					});
					setSubmitting(false);
				},
			});
		},
	});

	const addToEditedForms = () => {
		dispatch(addEditedFormToEditedFormsArray({ form: formId }));
	};

	return (
		<>
			<Toast ref={toast} />
			<ValidatedField<TextWithPagesElementFormState, string>
				name={"title"}
				label={"Interner Titel"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
				onChange={addToEditedForms}
			/>
			<Button
				stretch
				disabled={isEditingTextWithPagesElement}
				type={ButtonType.Submit}
				label="Speichern"
				onClick={() => formik.handleSubmit()}
			/>
			<div className={backButtonClass}>
				<Button
					stretch
					variant={ButtonVariant.Strong}
					type={ButtonType.Button}
					onClick={onBack}
					label="Zurück"
				/>
			</div>
			<TextWithPagesElementPagesEditor textWithPagesElementFragmentRef={element} />
		</>
	);
};
