import { ButtonType } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import { useCallback } from "react";
import { useFragment, useMutation } from "react-relay";
import { Button, ButtonVariant } from "@components/button";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { MemoizedRichTextEditor } from "@components/rich-text-editor";
import { RichTextEditorToolbar } from "@components/rich-text-editor/rich-text-editor.const";
import { ValidatedField } from "@components/ValidatedField";
import { reminderElementForm_EditReminderElementMutation } from "@relay/reminderElementForm_EditReminderElementMutation.graphql";
import { reminderElementForm_ReminderElementFragment$key } from "@relay/reminderElementForm_ReminderElementFragment.graphql";
import { addEditedFormToEditedFormsArray, resetArrayOfEditedForms } from "@store/slices/CoreSlice";
import { useTypedDispatch } from "@store/store.redux";
import { reminderElementFormValidationSchema } from "./reminder-element-form.consts";
import {
	EDIT_REMINDER_ELEMENT_MUTATION,
	REMINDER_ELEMENT_FRAGMENT,
} from "./reminder-element-form.graphql";
import { backButtonClass } from "./reminder-element-form.styles";
import { ReminderElementFormProps, ReminderElementFormState } from "./reminder-element-form.types";
import { stripHtml } from "../../../../util/html.utils";

export const ReminderElementForm = ({
	reminderElementFragmentRef,
	onBack,
}: ReminderElementFormProps) => {
	const element = useFragment<reminderElementForm_ReminderElementFragment$key>(
		REMINDER_ELEMENT_FRAGMENT,
		reminderElementFragmentRef,
	);
	const [editReminderElement, isEditingReminderElement] =
		useMutation<reminderElementForm_EditReminderElementMutation>(
			EDIT_REMINDER_ELEMENT_MUTATION,
		);

	const dispatch = useTypedDispatch();
	const formId = "ReminderElementForm";
	const formik = useFormik<ReminderElementFormState>({
		enableReinitialize: false,
		initialValues: {
			title: stripHtml(element.title),
			text: element.text,
		},
		validationSchema: reminderElementFormValidationSchema,
		onSubmit: (values) => {
			editReminderElement({
				variables: {
					input: {
						reminderElementId: element.id,
						title: values.title,
						text: values.text,
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});

	const handleFormEdited = useCallback(() => {
		dispatch(addEditedFormToEditedFormsArray({ form: formId }));
	}, [dispatch]);

	const handleOnBackClick = () => {
		onBack();
	};

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<ReminderElementFormState, string>
				name={"title"}
				label={"Titel"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>

			<ValidatedField<ReminderElementFormState, string>
				name={"text"}
				label={"Text"}
				onChange={handleFormEdited}
				component={(props) => (
					<MemoizedRichTextEditor
						{...props}
						height={200}
						toolbar={RichTextEditorToolbar.WithLists}
					/>
				)}
				formikConfig={formik}
			/>
			<Button
				stretch
				disabled={isEditingReminderElement}
				type={ButtonType.Submit}
				label="Speichern"
			/>

			<div className={backButtonClass}>
				<Button
					stretch
					type={ButtonType.Button}
					variant={ButtonVariant.Strong}
					onClick={handleOnBackClick}
					label="Zurück"
				/>
			</div>
		</form>
	);
};
