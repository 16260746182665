import { ButtonType, EpicIcons } from "@thekeytechnology/epic-ui";
import { useMutation } from "react-relay";
import { Button } from "@components/button";
import { type addNodeButton_CreateBranchChildMutation } from "@relay/addNodeButton_CreateBranchChildMutation.graphql";
import { type addNodeButton_CreateContentChildMutation } from "@relay/addNodeButton_CreateContentChildMutation.graphql";
import { type addNodeButton_CreateUploadAsyncElementMutation } from "@relay/addNodeButton_CreateUploadAsyncElementMutation.graphql";
import {
	CREATE_BRANCH_CHILD_MUTATION,
	CREATE_CONTENT_CHILD_MUTATION,
	CREATE_UPLOAD_ASYNC_ELEMENT_MUTATION,
} from "./add-node-button.graphql";
import { type AddNodeButtonProps } from "./add-node-button.types";
import { buttonWrapperClass } from "./add-note-button-styles";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../../translations/tree";

export const AddNodeButton = ({ parentBranchId, onSuccessful }: AddNodeButtonProps) => {
	const [createBranchChild, isCreatingBranchChild] =
		useMutation<addNodeButton_CreateBranchChildMutation>(CREATE_BRANCH_CHILD_MUTATION);

	const [createContentChild, isCreatingContentChild] =
		useMutation<addNodeButton_CreateContentChildMutation>(CREATE_CONTENT_CHILD_MUTATION);

	const [createUploadAsyncElement, isCreatingUploadAsyncElement] =
		useMutation<addNodeButton_CreateUploadAsyncElementMutation>(
			CREATE_UPLOAD_ASYNC_ELEMENT_MUTATION,
		);

	const onCreateBranchChildClick = () => {
		if (!parentBranchId) return;

		createBranchChild({
			variables: {
				input: {
					parentBranchId,
					title: TREE_I18N_MAP(TREE_I18N_KEY.newBranchNode),
				},
			},
			onCompleted: (response) => {
				onSuccessful(response.Admin.Tree.createBranchChild?.createdChildNode.id);
			},
		});
	};

	const onCreateContentChildClick = () => {
		if (!parentBranchId) return;

		createContentChild({
			variables: {
				input: {
					contentKind: "ELearning",
					parentBranchId,
					title: TREE_I18N_MAP(TREE_I18N_KEY.newELearningContentNode),
				},
			},
			onCompleted: (response) => {
				onSuccessful(response.Admin.Tree.createContentChild?.createdChildNode.id);
			},
		});
	};

	const onCreateUploadAsyncElementClick = () => {
		if (!parentBranchId) return;

		createContentChild({
			variables: {
				input: {
					contentKind: "Async",
					parentBranchId,
					title: TREE_I18N_MAP(TREE_I18N_KEY.newAsyncContentNode),
				},
			},
			onCompleted: (response) => {
				const asyncContentId = response.Admin.Tree.createContentChild?.createdChildNode.id;
				asyncContentId &&
					createUploadAsyncElement({
						variables: {
							input: {
								asyncContentId,
								title: "",
							},
						},
						onCompleted: () => {
							onSuccessful(asyncContentId);
						},
					});
			},
		});
	};

	return (
		<div className={buttonWrapperClass}>
			<Button
				type={ButtonType.Button}
				icon={EpicIcons.SITEMAP}
				tooltip={`${TREE_I18N_MAP(TREE_I18N_KEY.branchNode)} erstellen`}
				disabled={parentBranchId === undefined || isCreatingBranchChild}
				onClick={onCreateBranchChildClick}
			/>
			<Button
				type={ButtonType.Button}
				icon={EpicIcons.FILE}
				tooltip={`${TREE_I18N_MAP(TREE_I18N_KEY.eLearningContentNode)} erstellen`}
				disabled={parentBranchId === undefined || isCreatingContentChild}
				onClick={onCreateContentChildClick}
			/>
			<Button
				type={ButtonType.Button}
				icon={EpicIcons.ENVELOPE}
				tooltip={`${TREE_I18N_MAP(TREE_I18N_KEY.asyncContentNode)} erstellen`}
				disabled={parentBranchId === undefined || isCreatingUploadAsyncElement}
				onClick={onCreateUploadAsyncElementClick}
			/>
		</div>
	);
};
