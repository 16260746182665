import { graphql, useFragment } from "react-relay";
import React from "react";
import { type CodeCreatedAtColumn_DiscountCodeFragment$key } from "@relay/CodeCreatedAtColumn_DiscountCodeFragment.graphql";
import { DateTimeDisplay } from "../../../components/datetime-display/datetime-display.component";

const DISCOUNT_CODE_FRAGMENT = graphql`
	fragment CodeCreatedAtColumn_DiscountCodeFragment on DiscountCode {
		createdAt
	}
`;

type OwnProps = {
	discountCodeFragmentRef: CodeCreatedAtColumn_DiscountCodeFragment$key;
};

export const CodeCreatedAtColumn = ({ discountCodeFragmentRef }: OwnProps) => {
	const discountCode = useFragment<CodeCreatedAtColumn_DiscountCodeFragment$key>(
		DISCOUNT_CODE_FRAGMENT,
		discountCodeFragmentRef,
	);

	return <DateTimeDisplay value={discountCode.createdAt} />;
};
