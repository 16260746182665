import { Dropdown, PathParams } from "@thekeytechnology/epic-ui";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { useFragment, UseMutationConfig } from "react-relay";
import { useParams } from "react-router-dom";
import { useDispatchConditions } from "@hooks/use-dispatch-conditions";
import {
	DispatchCondition,
	DispatchConditionMutationConfig,
} from "@hooks/use-dispatch-conditions/use-dispatch-conditions.types";
import {
	dispatchConditionDropdown_MessageDispatchFragment$key,
	DispatchConditionKind,
} from "@relay/dispatchConditionDropdown_MessageDispatchFragment.graphql";

import { MessageDispatchesPath } from "@screens/message-dispatches";
import { DISPATCHES_TRANSLATIONS } from "../../../../translations/dispatch-conditions";
import { MESSAGE_DISPATCH_FRAGMENT } from "../dispatch-condition-dropdown/dispatch-condition-dropdown.graphql";
import { conditionsDropdownClass } from "../dispatch-condition-dropdown/dispatch-condition-dropdown.styles";
import {
	DispatchConditionDisplayType,
	DispatchConditionKindDropdownProps,
	DispatchConditionMutationInput,
	DispatchConditionSelection,
} from "../dispatch-condition-dropdown/dispatch-condition-dropdown.types";
import { DispatchInputSelectionModal } from "../dispatch-input-selection-modal/dispatch-input-selection-modal.component";

export const DispatchConditionDropdown = ({
	messageDispatchFragmentRef,
	isInclusion = true,
	onChange,
}: DispatchConditionKindDropdownProps) => {
	const toast = useRef<Toast>(null);
	const { messageDispatchId } = useParams<PathParams<typeof MessageDispatchesPath>>();
	const [selectedCondition, setSelectedCondition] = useState<DispatchCondition<any, any> | null>(
		null,
	);

	const messageDispatch = useFragment<dispatchConditionDropdown_MessageDispatchFragment$key>(
		MESSAGE_DISPATCH_FRAGMENT,
		messageDispatchFragmentRef ?? null,
	);

	const onCompleted = () => {
		onChange();
		toast.current?.show({
			severity: "success",
			life: 3000,
			summary: "Bedingung hinzugefügt",
			detail: "Die Bedingung wurde erfolgreich hinzugefügt",
		});
	};

	const onError = () => {
		toast.current?.show({
			severity: "error",
			life: 3000,
			summary: "Bedingung nicht hinzugefügt",
			detail: "Die Bedingung wurde nicht hinzugefügt",
		});
	};

	const createMutationConfig = (
		idInputType?: string,
		values?: DispatchConditionSelection,
	): UseMutationConfig<DispatchConditionMutationConfig> | undefined => {
		if (!messageDispatchId) return;

		const input: DispatchConditionMutationInput = {
			messageDispatchId,
		};

		switch (idInputType) {
			case "account":
				input.accountIds = values;
				break;
			case "user":
				input.userIds = values;
				break;
			case "rootNode":
				input.rootNodeIds = values;
				break;
			case "newsletterOptIn":
				input.optIn = !!values;
				break;
		}

		return {
			variables: {
				input,
			},
			onCompleted,
			onError,
		};
	};

	const handleConditionMutation = (
		condition: DispatchCondition<any, any>,
		values?: DispatchConditionSelection,
	) => {
		const mutationConfig = createMutationConfig(condition.idInputType, values);

		if (!mutationConfig) return;

		const input = mutationConfig.variables.input;

		if (isInclusion) {
			condition.includeMutation?.(input, mutationConfig);
		} else {
			condition.excludeMutation?.(input, mutationConfig);
		}
	};
	const handleOnChange = (value: string) => {
		const selectedValue = value as DispatchConditionKind;
		const condition = dispatchConditions.find((c) => c.kind === selectedValue);
		if (!condition) return;

		if (selectedValue === "AllUserDispatchCondition") {
			confirmDialog({
				message: "Bist du sicher, dass du alle Nutzer auswählen möchtest?",
				header: "Alle Nutzer auswählen",
				icon: "pi pi-info-circle",
				acceptClassName: "p-button-danger",
				acceptLabel: "Ja",
				rejectLabel: "Nein",
				accept: () => {
					setSelectedCondition(condition);
					if (condition.idInputType) return;
					handleConditionMutation(condition);
				},
			});
			return;
		}

		setSelectedCondition(condition);
		if (condition.idInputType) return;

		handleConditionMutation(condition);
	};

	const onInputSelected = (values: DispatchConditionSelection) => {
		const condition = dispatchConditions.find((c) => c.kind === selectedCondition?.kind);
		if (!condition) return;

		handleConditionMutation(condition, values);
		setSelectedCondition(null);
	};

	const dispatchConditions: Array<DispatchCondition<any, any>> = useDispatchConditions();

	const dispatchConditionsOptions: Array<DispatchConditionDisplayType> = dispatchConditions
		.filter((condition) => {
			const isAlreadyIncluded = messageDispatch?.includes.some(
				(included) => included.kind === condition.kind,
			);
			const isAlreadyExcluded = messageDispatch?.excludes.some(
				(excluded) => excluded.kind === condition.kind,
			);

			if (isInclusion) {
				return condition.includeMutation && !isAlreadyIncluded;
			}

			return condition.excludeMutation && !isAlreadyExcluded;
		})
		.map((condition) => ({
			label: DISPATCHES_TRANSLATIONS[condition.kind],
			value: condition.kind,
		}));

	return (
		<>
			<Toast ref={toast} />
			<Dropdown
				options={dispatchConditionsOptions}
				placeholder={"Bedingung auswählen..."}
				onChange={handleOnChange}
				className={conditionsDropdownClass}
			/>
			{selectedCondition && (
				<DispatchInputSelectionModal
					isInclusion={isInclusion}
					onHide={() => setSelectedCondition(null)}
					onInputSelected={onInputSelected}
					inputType={selectedCondition.idInputType}
				/>
			)}
		</>
	);
};
