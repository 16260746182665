import { graphql } from "react-relay";

export const ROOT_NODES_WITH_TREE_STATE_TYPE_FRAGMENT = graphql`
	fragment userRootNodeItem_RootNodesWithTreeStateTypeFragment on RootNodesWithTreeStateType {
		rootNode {
			...userRootNodeItem_LearnOpportunityV2Fragment
		}
		treeState {
			...userRootNodeItem_TreeStateFragment
		}
	}
`;

export const LEARN_OPPORTUNITY_V2_FRAGMENT = graphql`
	fragment userRootNodeItem_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		id
		structureDefinition {
			title
			... on LearnOpportunityRootStructureDefinition {
				extension {
					... on PublishedRootExtensionImpl {
						unlockInfo {
							kind
							... on UnlockInfoDemo {
								firstContentId
								firstContentCoordinatesIndexPath
								kind
							}
						}
					}
				}
			}
		}
	}
`;

export const TREE_STATE_FRAGMENT = graphql`
	fragment userRootNodeItem_TreeStateFragment on TreeState {
		id
		definition {
			status
			... on FinishedTreeStateDefinition {
				status
			}
			... on StartedTreeStateDefinition {
				status
				headContentNode {
					id
				}
			}
		}
		user {
			name
		}
		startedAt
		progressPercentage
		rootNode {
			parentId
			structureDefinition {
				coordinates {
					parentRef
				}
				definitionType
				title
			}
			typeDefinition {
				definitionType
				... on BranchTypeDefinition {
					childRefs
				}
				... on ContentTypeDefinition {
					contentKind
					... on ELearningContentTypeDefinition {
						elements {
							id
							elementType
							...ElementNode_ElementV2Fragment
						}
					}
				}
			}
		}
		extension {
			... on TkaTreeStateExtension {
				treeUnlockedState {
					when
					data {
						kind
						... on UnlockedByAdmin {
							unlockedByUser {
								id
								name
							}
						}
						... on UnlockedByLicense {
							license {
								id
								creationInfo {
									licenseDefinition {
										id
										data {
											name
										}
									}
									createdAt
								}
							}
						}
					}
				}
				submissions {
					id
					startedAt
					lastUpdated
					learnOpportunity {
						id
					}
				}
			}
		}
	}
`;

export const UNLOCK_TREE_BY_ADMIN_MUTATION = graphql`
	mutation userRootNodeItem_UnlockTreeByAdminMutation($input: UnlockTreeByAdminInput!) {
		Admin {
			Tree {
				unlockTreeByAdmin(input: $input) {
					publishedNode {
						...userRootNodeItem_LearnOpportunityV2Fragment
					}
					treeState {
						...userRootNodeItem_TreeStateFragment
					}
				}
			}
		}
	}
`;

export const CREATE_TREE_FOR_USER_MUTATION = graphql`
	mutation userRootNodeItem_CreateTreeForUserMutation($input: CreateTreeStateForUserInput!) {
		Admin {
			LearnV2 {
				createTreeStateForUser(input: $input) {
					publishedNode {
						...userRootNodeItem_LearnOpportunityV2Fragment
					}
					treeState {
						...userRootNodeItem_TreeStateFragment
					}
				}
			}
		}
	}
`;
