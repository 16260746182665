import { Button, EpicIcons } from "@thekeytechnology/epic-ui";
import { useMutation } from "react-relay";
import { createReminderElementButton_CreateReminderElementMutation } from "@relay/createReminderElementButton_CreateReminderElementMutation.graphql";
import { CREATE_REMINDER_ELEMENT_MUTATION } from "./create-reminder-element-button.graphql";
import { type CreateReminderElementButtonProps } from "./create-reminder-element-button.types";

export const CreateReminderElementButton = ({
	eLearningContentNodeId,
}: CreateReminderElementButtonProps) => {
	const [createReminderElement, isCreatingReminderElement] =
		useMutation<createReminderElementButton_CreateReminderElementMutation>(
			CREATE_REMINDER_ELEMENT_MUTATION,
		);

	const handleCreateReminderElementOnClick = () => {
		createReminderElement({
			variables: {
				input: {
					title: "Neues Erinnerung-Element",
					text: "Neuer Text",
					eLearningContentNodeId: eLearningContentNodeId,
				},
			},
		});
	};

	return (
		<Button
			disabled={isCreatingReminderElement}
			tooltip={`Reminder-Baustein anlegen`}
			icon={EpicIcons.CLOCK}
			onClick={handleCreateReminderElementOnClick}
		/>
	);
};
