import { DeleteButton } from "@thekeytechnology/epic-ui";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useMutation, usePaginationFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { VerticalContainerSpacingWrapper } from "@corestyle/component-theme/default-spacings.component";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type instructorsTable_DeleteMutation } from "@relay/instructorsTable_DeleteMutation.graphql";
import { type instructorsTable_InstructorsListFragment$key } from "@relay/instructorsTable_InstructorsListFragment.graphql";
import { type instructorsTable_Refetch } from "@relay/instructorsTable_Refetch.graphql";
import { Paths } from "@routes/paths";
import {
	DELETE_INSTRUCTOR_MUTATION,
	INSTRUCTORS_TABLE_FRAGMENT,
} from "./instructors-table.graphql";
import { ActionButtonsWrapper, ButtonContainer } from "./instructors-table.styles";
import { type InstructorsTableProps } from "./instructors-table.types";

export const InstructorsTable = ({ instructorsFragmentRef }: InstructorsTableProps) => {
	const navigate = useNavigate();
	const {
		data: Admin,
		hasPrevious,
		hasNext,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<
		instructorsTable_Refetch,
		instructorsTable_InstructorsListFragment$key
	>(INSTRUCTORS_TABLE_FRAGMENT, instructorsFragmentRef);
	const instructors = Admin.Admin.Instructor.InstructorImpls.edges;
	const [deleteInstructor, isDeleting] = useMutation<instructorsTable_DeleteMutation>(
		DELETE_INSTRUCTOR_MUTATION,
	);

	const canCreate = useHasPermissions(["UserInAccountPermission_Instructors_CreateInstructors"]);
	const canUpdate = useHasPermissions(["UserInAccountPermission_Instructors_UpdateInstructors"]);
	const canDelete = useHasPermissions(["UserInAccountPermission_Instructors_DeleteInstructors"]);

	const onDelete = (ids: string[]) => {
		deleteInstructor({
			variables: {
				input: {
					ids,
				},
				connections: [Admin.Admin.Instructor.InstructorImpls.__id],
			},
		});
	};

	function handleOnClick() {
		navigate(Paths.instructors.withId("new").edit.path);
	}

	return (
		<>
			{canCreate && (
				<ButtonContainer>
					<Button
						onClick={() => {
							handleOnClick();
						}}
					>
						Neuer Expert*in
					</Button>
				</ButtonContainer>
			)}
			<VerticalContainerSpacingWrapper>
				<DataTable
					emptyMessage="Keine Expert*innen"
					value={instructors?.map((b) => b!.node!) as any[]}
				>
					<Column header="Name" field={"name"} />
					<Column header="Position" field={"position"} />

					{(canUpdate || canDelete) && (
						<Column
							header="Aktionen"
							style={{ width: "20%" }}
							body={(item) => (
								<ActionButtonsWrapper>
									{canUpdate && (
										<Button
											onClick={() => {
												navigate(
													Paths.instructors.withId(item.superId).edit
														.path,
												);
											}}
											icon={"pi pi-pencil"}
										/>
									)}

									{canDelete && (
										<DeleteButton
											disabled={isDeleting}
											onDelete={onDelete}
											singularName={"Trainer"}
											pluralName={"Trainer:innen"}
											value={[item.id]}
										/>
									)}
								</ActionButtonsWrapper>
							)}
						/>
					)}
				</DataTable>
			</VerticalContainerSpacingWrapper>
			<div className="flex justify-content-center align-items-center">
				<Button
					disabled={!hasPrevious}
					onClick={() => loadPrevious(20)}
					className="mr-3 p-button-secondary"
				>
					Zurück
				</Button>
				<Button
					className="p-button-secondary"
					disabled={!hasNext}
					onClick={() => loadNext(20)}
				>
					Weiter
				</Button>
			</div>
		</>
	);
};
