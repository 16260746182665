/**
 * @generated SignedSource<<5518630f35d1c6b1eaf1b234cbcc1dfe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DiscountCodeTable_QueryFragment$data = {
  readonly node: {
    readonly discountCodes?: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"DiscountCodeTable_DiscountCodeFragment">;
        };
      } | null | undefined> | null | undefined;
      readonly pageInfo: {
        readonly endCursor: string | null | undefined;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string | null | undefined;
      };
    };
    readonly " $fragmentSpreads": FragmentRefs<"DiscountCodeCodesTableHeader_DiscountActionFragment">;
  } | null | undefined;
  readonly " $fragmentType": "DiscountCodeTable_QueryFragment";
};
export type DiscountCodeTable_QueryFragment$key = {
  readonly " $data"?: DiscountCodeTable_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DiscountCodeTable_QueryFragment">;
};

import DiscountCodeTable_Refetch_graphql from './DiscountCodeTable_Refetch.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "node",
  "discountCodes"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": DiscountCodeTable_Refetch_graphql
    }
  },
  "name": "DiscountCodeTable_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "discountCodes",
              "args": null,
              "concreteType": "DiscountCodesV2Connection",
              "kind": "LinkedField",
              "name": "__DiscountCodeTable_discountCodes_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DiscountCodesV2Edge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "DiscountCode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "DiscountCodeTable_DiscountCodeFragment",
                          "selections": [
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "CodeIdColumn_DiscountCodeFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "CodeCodeColumn_DiscountCodeFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "CodeNumUsagesColumn_DiscountCodeFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "CodeCreatedAtColumn_DiscountCodeFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "CodeActionColumn_DiscountCodeFragment"
                            }
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasPreviousPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__id",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DiscountCodeCodesTableHeader_DiscountActionFragment"
            }
          ],
          "type": "DiscountAction",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "2ef19dfbf56d89331a5220a58dd4e798";

export default node;
