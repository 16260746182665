import { graphql } from "react-relay";

export const QUERY = graphql`
	query userRootNodeItemTree_Query($rootId: ID!) {
		Admin {
			Tree {
				Tree(rootId: $rootId) {
					id
					rootNode {
						id
						hasBeenPublishedOnce
						structureDefinition {
							title
						}
						...userRootNodeItemTree_TreeNodeInlineFragment
					}
					nodes {
						...userRootNodeItemTree_TreeNodeInlineFragment
					}
				}
			}
		}
	}
`;

export const TREE_NODE_INLINE_FRAGMENT = graphql`
	fragment userRootNodeItemTree_TreeNodeInlineFragment on TreeNode @inline {
		id
		hasBeenPublishedOnce
		parentId
		structureDefinition {
			coordinates {
				parentRef
			}
			definitionType
			title
		}
		typeDefinition {
			definitionType
			... on BranchTypeDefinition {
				childRefs
			}
			... on ContentTypeDefinition {
				contentKind
				... on ELearningContentTypeDefinition {
					elements {
						id
						elementType
						...ElementNode_ElementV2Fragment
					}
				}
			}
		}
		...Node_TreeNodeFragment
	}
`;
