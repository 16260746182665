import { graphql } from "react-relay";

export const QUERY = graphql`
	query discountActionEditScreen_Query($id: ID!) {
		node(id: $id) {
			... on DiscountAction {
				title
				...discountActionForm_DiscountActionFragment
			}
		}
		...DiscountCodeTable_QueryFragment @arguments(first: 10, after: null, id: $id)
	}
`;
