/**
 * @generated SignedSource<<712d0fed683b6c0f26cadc894244c642>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CodeNumUsagesColumn_DiscountCodeFragment$data = {
  readonly numUsages: number;
  readonly " $fragmentType": "CodeNumUsagesColumn_DiscountCodeFragment";
};
export type CodeNumUsagesColumn_DiscountCodeFragment$key = {
  readonly " $data"?: CodeNumUsagesColumn_DiscountCodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CodeNumUsagesColumn_DiscountCodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CodeNumUsagesColumn_DiscountCodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numUsages",
      "storageKey": null
    }
  ],
  "type": "DiscountCode",
  "abstractKey": null
};

(node as any).hash = "dd21e08a4f4d46a7db617c0e92125e32";

export default node;
