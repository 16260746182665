/**
 * @generated SignedSource<<6f69bbbfa4a91b2cc1fde246596e6006>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LicenseDefinitionDataKind = "AllTrees" | "Tree" | "Trees";
export type LicenseDefinitionsTable_Refetch$variables = {
  after?: string | null | undefined;
  dataKinds: ReadonlyArray<LicenseDefinitionDataKind>;
  first?: number | null | undefined;
  nameMatchRegex?: string | null | undefined;
};
export type LicenseDefinitionsTable_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"licenseDefinitionsTable_LicenseDefinitionsFragment">;
};
export type LicenseDefinitionsTable_Refetch = {
  response: LicenseDefinitionsTable_Refetch$data;
  variables: LicenseDefinitionsTable_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dataKinds"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "nameMatchRegex"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "dataKinds",
    "variableName": "dataKinds"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "nameMatchRegex",
    "variableName": "nameMatchRegex"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LicenseDefinitionsTable_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "licenseDefinitionsTable_LicenseDefinitionsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LicenseDefinitionsTable_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LicenseDefinitionAdminSchema",
            "kind": "LinkedField",
            "name": "LicenseDefinition",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "LicenseDefinitionsConnection",
                "kind": "LinkedField",
                "name": "SearchLicenseDefinitions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicenseDefinitionsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LicenseDefinition",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "kind",
                                "storageKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LicenseDefinitionRootInfo",
                                    "kind": "LinkedField",
                                    "name": "rootInfo",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "rootId",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "title",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "TreeLicenseDefinitionData",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "nameMatchRegex",
                  "dataKinds"
                ],
                "handle": "connection",
                "key": "LicenseDefinitionsTable_SearchLicenseDefinitions",
                "kind": "LinkedHandle",
                "name": "SearchLicenseDefinitions"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "536a049144aeeba7e0f8aebb1b69b02b",
    "id": null,
    "metadata": {},
    "name": "LicenseDefinitionsTable_Refetch",
    "operationKind": "query",
    "text": "query LicenseDefinitionsTable_Refetch(\n  $after: String\n  $dataKinds: [LicenseDefinitionDataKind!]!\n  $first: Int = 20\n  $nameMatchRegex: String\n) {\n  ...licenseDefinitionsTable_LicenseDefinitionsFragment_2Dfdw6\n}\n\nfragment editTreeLicenseDefinitionButton_LicenseDefinitionFragment on LicenseDefinition {\n  id\n  data {\n    __typename\n    ... on TreeLicenseDefinitionData {\n      name\n      rootInfo {\n        rootId\n        title\n      }\n    }\n  }\n}\n\nfragment licenseDefinitionsTable_LicenseDefinitionsFragment_2Dfdw6 on Query {\n  Admin {\n    LicenseDefinition {\n      SearchLicenseDefinitions(first: $first, after: $after, nameMatchRegex: $nameMatchRegex, dataKinds: $dataKinds) {\n        edges {\n          node {\n            id\n            data {\n              __typename\n              name\n              kind\n            }\n            ...editTreeLicenseDefinitionButton_LicenseDefinitionFragment\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8327e8ee4d85e0af7aaac650349c23ff";

export default node;
