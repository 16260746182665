/**
 * @generated SignedSource<<73e6db083ef2eb3e27e2aa15bebe1b12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DiscountCodeCodesTableHeader_DiscountActionFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CreateRandomCodesButton_DiscountActionFragment" | "CreateSingleCodeButton_DiscountActionFragment" | "DownloadCodesCsvButton_DiscountActionFragment">;
  readonly " $fragmentType": "DiscountCodeCodesTableHeader_DiscountActionFragment";
};
export type DiscountCodeCodesTableHeader_DiscountActionFragment$key = {
  readonly " $data"?: DiscountCodeCodesTableHeader_DiscountActionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DiscountCodeCodesTableHeader_DiscountActionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DiscountCodeCodesTableHeader_DiscountActionFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateRandomCodesButton_DiscountActionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateSingleCodeButton_DiscountActionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DownloadCodesCsvButton_DiscountActionFragment"
    }
  ],
  "type": "DiscountAction",
  "abstractKey": null
};

(node as any).hash = "6225c02e1a45824c7ba4918e787658dd";

export default node;
