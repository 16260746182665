import { graphql, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { type DeleteDiscountCodeButton_DeleteMutation } from "@relay/DeleteDiscountCodeButton_DeleteMutation.graphql";

const DELETE_DISCOUNT_CODE_MUTATION = graphql`
	mutation DeleteDiscountCodeButton_DeleteMutation($input: DeleteDiscountCodeInput!) {
		Admin {
			Billing {
				deleteDiscountCode(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;

type OwnProps = {
	id: string;
	className?: string;
	connectionId: string;
};

export const DeleteDiscountCodeButton = ({ className, id }: OwnProps) => {
	const [deleteCode, isLoading] = useMutation<DeleteDiscountCodeButton_DeleteMutation>(
		DELETE_DISCOUNT_CODE_MUTATION,
	);

	return (
		<Button
			icon="pi pi-trash"
			tooltip="Aktion löschen"
			loading={isLoading}
			disabled={isLoading}
			className={className}
			onClick={() => {
				deleteCode({
					variables: {
						input: {
							discountCodeId: id,
						},
					},
				});
			}}
		/>
	);
};
