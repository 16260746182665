import { graphql, useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import React, { useRef, useState } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { CreateRandomCodesButton_DiscountActionFragment$key } from "@relay/CreateRandomCodesButton_DiscountActionFragment.graphql";
import { CreateRandomCodesButton_CreateMutation } from "@relay/CreateRandomCodesButton_CreateMutation.graphql";

const DISCOUNT_ACTION_FRAGMENT = graphql`
	fragment CreateRandomCodesButton_DiscountActionFragment on DiscountAction {
		id
	}
`;

const CREATE_RANDOM_DISCOUNT_CODES_MUTATION = graphql`
	mutation CreateRandomCodesButton_CreateMutation(
		$input: CreateRandomDiscountCodesInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				createRandomDiscountCodes(input: $input) {
					connection {
						edges @prependEdge(connections: $connections) {
							node {
								...DiscountCodeTable_DiscountCodeFragment
							}
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	className?: string;
	connectionId: string;
	discountActionFragmentRef: CreateRandomCodesButton_DiscountActionFragment$key;
}

export const CreateRandomCodesButton = ({
	className,
	connectionId,
	discountActionFragmentRef,
}: OwnProps) => {
	const discountAction = useFragment<CreateRandomCodesButton_DiscountActionFragment$key>(
		DISCOUNT_ACTION_FRAGMENT,
		discountActionFragmentRef,
	);
	const [createRandomCodes, isLoading] = useMutation<CreateRandomCodesButton_CreateMutation>(
		CREATE_RANDOM_DISCOUNT_CODES_MUTATION,
	);

	const [amount, setAmount] = useState<number>(0);

	const overlayPanel = useRef<OverlayPanel>(null);
	const toast = useRef<Toast>(null);

	return (
		<>
			<Dialog
				header="Rabattcodes werden erstellt"
				visible={isLoading}
				closable={false}
				onHide={() => {}}
			>
				<div className="flex justify-content-center align-items-center">
					<ProgressSpinner />
				</div>
			</Dialog>
			<Toast ref={toast} />
			<Button
				label="Randomisiert"
				loading={isLoading}
				disabled={isLoading}
				className={className}
				onClick={(e) => overlayPanel.current?.toggle(e)}
			/>
			<OverlayPanel ref={overlayPanel}>
				<span className="p-float-label mb-2 mt-3">
					<InputNumber
						max={1000}
						min={1}
						id="nameOfCode"
						value={amount}
						onChange={(e) => setAmount(e.value || 0)}
					/>
					<label htmlFor="nameOfCode">Anzahl der Codes</label>
				</span>
				<Button
					label="Randomisierte Codes erstellen"
					loading={isLoading}
					disabled={isLoading}
					onClick={() => {
						createRandomCodes({
							variables: {
								input: {
									discountActionId: discountAction.id,
									number: amount,
								},
								connections: [connectionId],
							},
							onCompleted: () => {
								toast.current?.show({
									severity: "success",
									life: 3000,
									summary: "Codes erstellt",
									detail: "Randomisierte codes wurden erfolgreich erstellt",
								});
							},
						});
					}}
				/>
			</OverlayPanel>
		</>
	);
};
