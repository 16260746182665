import React from "react";
import { graphql, useFragment } from "react-relay";
import { CodeNumUsagesColumn_DiscountCodeFragment$key } from "@relay/CodeNumUsagesColumn_DiscountCodeFragment.graphql";

const DISCOUNT_CODE_FRAGMENT = graphql`
	fragment CodeNumUsagesColumn_DiscountCodeFragment on DiscountCode {
		numUsages
	}
`;

interface OwnProps {
	discountCodeFragmentRef: CodeNumUsagesColumn_DiscountCodeFragment$key;
}

export const CodeNumUsagesColumn = ({ discountCodeFragmentRef }: OwnProps) => {
	const discountCode = useFragment<CodeNumUsagesColumn_DiscountCodeFragment$key>(
		DISCOUNT_CODE_FRAGMENT,
		discountCodeFragmentRef,
	);

	return <div>{discountCode.numUsages}</div>;
};
