import { PathParams } from "@thekeytechnology/epic-ui";
import { Toast } from "primereact/toast";
import React, { useCallback, useRef, useState } from "react";
import { useRefetchableFragment } from "react-relay";
import { useParams } from "react-router-dom";

import { PageItem } from "@features/pages-editor/pages-editor.types";
import { usePageMutations } from "@hooks/use-message-page-mutations";

import {
	messagePagesEditor_PagesFragment$data,
	messagePagesEditor_PagesFragment$key,
} from "@relay/messagePagesEditor_PagesFragment.graphql";
import { messagePagesEditor_Refetch } from "@relay/messagePagesEditor_Refetch.graphql";
import { MessagePageFormState } from "@screens/message-definition-edit/parts/message-page-form/message-page-form.types";
import { MessagePageFormDialog } from "@screens/message-definition-edit/parts/message-page-form-dialog";
import { PAGES_FRAGMENT } from "@screens/message-definition-edit/parts/message-pages-editor/message-pages-editor.graphql";
import { MessagePagesEditorProps } from "@screens/message-definition-edit/parts/message-pages-editor/message-pages-editor.types";
import { PageButtonFormState } from "@screens/message-definition-edit/parts/page-button-form/page-button-form.types";
import { MessageDefinitionsPath } from "@screens/message-definitions";
import { PagesEditor } from "@features/pages-editor";

export const MessagePagesEditor = ({ messageDefinitionFragmentRef }: MessagePagesEditorProps) => {
	const [isPageFormDialogVisible, setIsPageFormDialogVisible] = useState(false);
	const [selectedPage, setSelectedPage] = useState<
		messagePagesEditor_PagesFragment$data["pages"][number] | null
	>(null);

	const { messageDefinitionId } = useParams<PathParams<typeof MessageDefinitionsPath>>();
	if (!messageDefinitionId) {
		// TODO: prettier
		throw new Error("messageDefinitionId is required");
	}

	const toast = useRef<Toast>(null);
	const [data, refetch] = useRefetchableFragment<
		messagePagesEditor_Refetch,
		messagePagesEditor_PagesFragment$key
	>(PAGES_FRAGMENT, messageDefinitionFragmentRef);
	const refresh = useCallback(() => {
		refetch({}, { fetchPolicy: "network-only" });
	}, [refetch]);

	const pages = data?.pages.map((page) => page) || [];

	const { handleOnCreatePage, handleOnEditPage, handleDeletePage, handleRearrangePages } =
		usePageMutations({ messageDefinitionId, toast, refresh });

	const formattedPages: Array<PageItem> = pages.map((page) => {
		return {
			title: page.internalTitle,
			...page,
		};
	});

	const handlePageFormOnSubmit = (
		pageValues: MessagePageFormState,
		buttonValues?: PageButtonFormState,
	) => {
		selectedPage
			? handleOnEditPage(pageValues, selectedPage?.id, buttonValues)
			: handleOnCreatePage(pageValues, buttonValues);
		setIsPageFormDialogVisible(false);
		setSelectedPage(null);
	};

	const handleOnShowCreatePage = () => {
		setSelectedPage(null);
		setIsPageFormDialogVisible(true);
	};

	const handleOnShowEditPage = (pageId: string) => {
		const page = pages.find((page) => page.id === pageId);
		if (!page) {
			return;
		}
		setSelectedPage(page);
		setIsPageFormDialogVisible(true);
	};

	const handleOnHidePageFormDialog = () => {
		setSelectedPage(null);
		setIsPageFormDialogVisible(false);
	};

	return (
		<>
			<Toast ref={toast} />
			<MessagePageFormDialog
				isVisible={isPageFormDialogVisible}
				isCreating={selectedPage === null}
				pageFragmentRef={selectedPage}
				onSubmit={handlePageFormOnSubmit}
				onHide={handleOnHidePageFormDialog}
			/>
			<PagesEditor
				pages={formattedPages}
				onShowCreatePage={handleOnShowCreatePage}
				onShowEditPage={handleOnShowEditPage}
				onDeletePage={handleDeletePage}
				onRearrangePages={handleRearrangePages}
			/>
		</>
	);
};
