import { type PathParams } from "@thekeytechnology/epic-ui";
import { ConnectionHandler, useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";
import { BaseScreen } from "@components/base-screen";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type instructorEditScreen_CreateMutation } from "@relay/instructorEditScreen_CreateMutation.graphql";
import { type instructorEditScreen_InstructorFragment$key } from "@relay/instructorEditScreen_InstructorFragment.graphql";
import { type instructorEditScreen_Query } from "@relay/instructorEditScreen_Query.graphql";
import { type instructorEditScreen_UpdateMutation } from "@relay/instructorEditScreen_UpdateMutation.graphql";
import { Paths } from "@routes/paths";
import { type InstructorsPath } from "@screens/instructors";
import {
	CREATE_MUTATION,
	INSTRUCTOR_FRAGMENT,
	QUERY,
	UPDATE_MUTATION,
} from "./instructor-edit.graphql";
import { EditInstructorForm } from "./parts/EditInstructorForm/EditInstructorForm";
import { NoAccess } from "../../components/no-access";
import { type FileV2 } from "../../features/files/file-selection-field";

export const InstructorEditScreen = () => {
	const navigate = useNavigate();
	const { instructorId } = useParams<PathParams<typeof InstructorsPath>>();
	const query = useLazyLoadQuery<instructorEditScreen_Query>(QUERY, {
		id: instructorId ?? "",
		skip: !instructorId || instructorId === "new",
	});

	const instructor = useFragment<instructorEditScreen_InstructorFragment$key>(
		INSTRUCTOR_FRAGMENT,
		query.node || null,
	);
	const [create, isCreating] = useMutation<instructorEditScreen_CreateMutation>(CREATE_MUTATION);
	const [update, isUpdating] = useMutation<instructorEditScreen_UpdateMutation>(UPDATE_MUTATION);

	const canReadCreateAndUpdate = useHasPermissions([
		"UserInAccountPermission_Instructors_ReadInstructors",
		"UserInAccountPermission_Instructors_CreateInstructors",
		"UserInAccountPermission_Instructors_UpdateInstructors",
	]);

	if (!canReadCreateAndUpdate) return <NoAccess />;

	return (
		<BaseScreen title="Bearbeite Expert*in">
			<EditInstructorForm
				initialValues={{
					name: instructor?.name ?? "Neue*r Expert*in",
					position: instructor?.position ?? undefined,
					shortDescription: instructor?.shortDescription ?? undefined,
					description: instructor?.description ?? undefined,
					image: instructor?.image as FileV2,
				}}
				loading={isCreating || isUpdating}
				onSubmit={(values) => {
					const { image, ...restValues } = values;
					if (instructor) {
						update({
							variables: {
								input: {
									id: instructor.superId,
									data: { ...restValues, imageId: image?.id },
								},
							},
							onCompleted: () => {
								navigate(Paths.instructors.path);
							},
						});
					} else {
						create({
							variables: {
								input: {
									data: {
										name: values.name,
										imageId: values.image?.id,
										description: values.description,
										shortDescription: values.shortDescription,
										position: values.position,
									},
								},
								connections: [
									ConnectionHandler.getConnectionID(
										"client:root:Admin:Instructor",
										"InstructorsTable_Instructors",
									),
								],
							},
							onCompleted: () => {
								navigate(Paths.instructors.path);
							},
						});
					}
				}}
			/>
		</BaseScreen>
	);
};
