import { Button, Form } from "@thekeytechnology/framework-react-components";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { EMAIL_VARIABLES } from "@translations/email";
import {
	type EditEmailTemplateFormProps,
	type EditEmailTemplateFormState,
} from "./edit-email-template-form.types";
import { WarningUnsavedChangesDialog } from "../../../../components/WarningUnsavedChangesDialog";
import { DefaultTextFieldComponent } from "../../../../components/DefaultTextInput";
import { ValidatedField } from "../../../../components/ValidatedField";
import { useCallbackPrompt } from "../../../../hooks/UseCallBackPrompt";
import { useTypedDispatch, useTypedSelector } from "../../../../store/store.redux";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
	selectArrayOfEditedForms,
} from "../../../../store/slices/CoreSlice";
import { MemoizedRichTextEditor } from "@components/rich-text-editor";
import { RichTextEditorToolbar } from "@components/rich-text-editor/rich-text-editor.const";

export const EditEmailTemplateForm = ({
	variables,
	loading,
	initialValues,
	onSubmit,
}: EditEmailTemplateFormProps) => {
	const dispatch = useTypedDispatch();
	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);
	const formId = "EditEmailTemplateFormComponent";
	const [showPrompt, confirmNavigation, cancelNavigation, setShowPrompt] = useCallbackPrompt(
		arrayOfEditedForms.length > 0,
	);
	const formik = useFormik<EditEmailTemplateFormState>({
		initialValues: {
			subject: initialValues?.subject ?? "Betreff",
			preview: initialValues?.preview ?? "Vorschautext",
			body: initialValues?.body ?? "Nachricht",
		},
		validationSchema: Yup.object().shape({
			subject: Yup.string().required("Betreff wird benötigt"),
			body: Yup.string().required("Nachricht Code wird benötigt"),
		}),
		onSubmit: (values, { setSubmitting }) => {
			onSubmit({
				...values,
			});
			setSubmitting(false);
			dispatch(resetArrayOfEditedForms());
		},
	});

	const handleOnChange = () => {
		dispatch(addEditedFormToEditedFormsArray({ form: formId }));
	};

	const handleOnClick = () => {
		formik.handleSubmit();
	};

	return (
		<>
			{showPrompt && (
				<WarningUnsavedChangesDialog
					confirmNavigation={confirmNavigation}
					setShowDialog={setShowPrompt}
					cancelNavigation={cancelNavigation}
				/>
			)}
			<Form onSubmit={formik.handleSubmit}>
				<ValidatedField<EditEmailTemplateFormState, string>
					name={"subject"}
					label={"Betreff"}
					component={DefaultTextFieldComponent}
					onChange={handleOnChange}
					formikConfig={formik}
				/>
				<ValidatedField<EditEmailTemplateFormState, string>
					name={"preview"}
					label={"Vorschautext"}
					helpText={"Dieser Text wird in manchen Mailprogrammen angezeigt"}
					component={DefaultTextFieldComponent}
					onChange={handleOnChange}
					formikConfig={formik}
				/>

				<ValidatedField<EditEmailTemplateFormState, string>
					name={"body"}
					label={"Nachricht"}
					component={(props) => (
						<MemoizedRichTextEditor
							{...props}
							height={200}
							toolbar={RichTextEditorToolbar.WithTextColor}
						/>
					)}
					onChange={handleOnChange}
					formikConfig={formik}
				/>

				<h3>Verfügbare Variablen</h3>
				<p>
					Diese Variablen können in allen obigen Feldern eingefügt werden und werden durch
					die entsprechenden Texte ersetzt.
				</p>
				<ul>
					{variables.map((variable) => {
						return (
							<li key={variable}>
								<strong>%%{variable}%%</strong> - {EMAIL_VARIABLES[variable]}
							</li>
						);
					})}
				</ul>

				<Button
					disabled={loading}
					content={{ label: "Speichern" }}
					onClick={handleOnClick}
				/>
			</Form>
		</>
	);
};
