import { H2Span } from "@thekeytechnology/epic-ui";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useCallback } from "react";
import { useRefetchableFragment } from "react-relay";
import { dispatchInclusions_MessageDispatchFragment$key } from "@relay/dispatchInclusions_MessageDispatchFragment.graphql";
import { dispatchInclusions_Refetch } from "@relay/dispatchInclusions_Refetch.graphql";
import { DispatchConditionDropdown } from "../dispatch-condition-dropdown";
import { DispatchConditionPreview } from "../dispatch-conditions-preview";
import { MESSAGE_DISPATCH_FRAGMENT } from "../dispatch-inclusions/dispatch-inclusions.graphql";
import { DispatchInclusionsProps } from "../dispatch-inclusions/dispatch-inclusions.types";

export const DispatchInclusions = ({ messageDispatchFragmentRef }: DispatchInclusionsProps) => {
	const [messageDispatch, refetch] = useRefetchableFragment<
		dispatchInclusions_Refetch,
		dispatchInclusions_MessageDispatchFragment$key
	>(MESSAGE_DISPATCH_FRAGMENT, messageDispatchFragmentRef ?? null);

	const refresh = useCallback(() => {
		refetch({}, { fetchPolicy: "network-only" });
	}, []);
	return (
		<>
			<H2Span>Einschlüsse</H2Span>
			<ConfirmDialog />
			<DispatchConditionDropdown
				isInclusion
				onChange={refresh}
				messageDispatchFragmentRef={messageDispatch}
			/>
			<DispatchConditionPreview
				onChange={refresh}
				messageDispatchFragmentRef={messageDispatch}
			/>
		</>
	);
};
