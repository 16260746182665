import React, { Suspense } from "react";
import { BaseScreen } from "@components/base-screen";
import { NoAccess } from "@components/no-access";
import { CreateTreeLicenseDefinitionButton } from "@features/license-definitions/create-tree-license-definition-button";
import { LicenseDefinitionsTable } from "@features/license-definitions/license-definitions-table";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { LicenseDefinitionsScreenContext } from "./license-defintions.context";

export const LicenseDefinitionsScreen = () => {
	const [connectionId, setConnectionId] = React.useState("");

	const canModify = useHasPermissions(["UserInAccountPermission_LicenseAdmin_Modify"]);
	const canRead = useHasPermissions(["UserInAccountPermission_LicenseAdmin_Read"]);

	const handleSetConnectionId = (id: string) => {
		setConnectionId(id);
	};

	if (!canRead) return <NoAccess />;

	return (
		<LicenseDefinitionsScreenContext.Provider
			value={{ connectionId, setConnectionId: handleSetConnectionId }}
		>
			<BaseScreen title="Lizenzdefinitionen">
				{canModify && <CreateTreeLicenseDefinitionButton />}
				<Suspense fallback={<div>Lädt...</div>}>
					<LicenseDefinitionsTable />
				</Suspense>
			</BaseScreen>
		</LicenseDefinitionsScreenContext.Provider>
	);
};
