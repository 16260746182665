/**
 * @generated SignedSource<<2b0d2d94a8d8cfecbe304e36168f8b20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LicenseDefinitionDataKind = "AllTrees" | "Tree" | "Trees";
export type CreateTreeLicenseDefinitionInput = {
  clientMutationId?: string | null | undefined;
  data: TreeLicenseDefinitionDataInput;
};
export type TreeLicenseDefinitionDataInput = {
  name: string;
  rootId: string;
};
export type createTreeLicenseDefinitionButton_CreateTreeLicenseDefinitionMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateTreeLicenseDefinitionInput;
};
export type createTreeLicenseDefinitionButton_CreateTreeLicenseDefinitionMutation$data = {
  readonly Admin: {
    readonly LicenseDefinition: {
      readonly createTreeLicenseDefinition: {
        readonly edge: {
          readonly node: {
            readonly data: {
              readonly kind: LicenseDefinitionDataKind;
              readonly name: string;
            };
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"editTreeLicenseDefinitionButton_LicenseDefinitionFragment">;
          };
        };
      } | null | undefined;
    };
  };
};
export type createTreeLicenseDefinitionButton_CreateTreeLicenseDefinitionMutation = {
  response: createTreeLicenseDefinitionButton_CreateTreeLicenseDefinitionMutation$data;
  variables: createTreeLicenseDefinitionButton_CreateTreeLicenseDefinitionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createTreeLicenseDefinitionButton_CreateTreeLicenseDefinitionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LicenseDefinitionAdminMutationSchema",
            "kind": "LinkedField",
            "name": "LicenseDefinition",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateTreeLicenseDefinitionPayload",
                "kind": "LinkedField",
                "name": "createTreeLicenseDefinition",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicenseDefinitionsEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LicenseDefinition",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "editTreeLicenseDefinitionButton_LicenseDefinitionFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createTreeLicenseDefinitionButton_CreateTreeLicenseDefinitionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LicenseDefinitionAdminMutationSchema",
            "kind": "LinkedField",
            "name": "LicenseDefinition",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateTreeLicenseDefinitionPayload",
                "kind": "LinkedField",
                "name": "createTreeLicenseDefinition",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicenseDefinitionsEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LicenseDefinition",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              (v5/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LicenseDefinitionRootInfo",
                                    "kind": "LinkedField",
                                    "name": "rootInfo",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "rootId",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "title",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "TreeLicenseDefinitionData",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "edge",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "66631236189695b34cdcaa8cafd314fc",
    "id": null,
    "metadata": {},
    "name": "createTreeLicenseDefinitionButton_CreateTreeLicenseDefinitionMutation",
    "operationKind": "mutation",
    "text": "mutation createTreeLicenseDefinitionButton_CreateTreeLicenseDefinitionMutation(\n  $input: CreateTreeLicenseDefinitionInput!\n) {\n  Admin {\n    LicenseDefinition {\n      createTreeLicenseDefinition(input: $input) {\n        edge {\n          node {\n            id\n            data {\n              __typename\n              name\n              kind\n            }\n            ...editTreeLicenseDefinitionButton_LicenseDefinitionFragment\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment editTreeLicenseDefinitionButton_LicenseDefinitionFragment on LicenseDefinition {\n  id\n  data {\n    __typename\n    ... on TreeLicenseDefinitionData {\n      name\n      rootInfo {\n        rootId\n        title\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7ab9b61a2d173c8bb4e75ca984b1a5fd";

export default node;
