import { ButtonType, EpicIcons, Icon, Tooltip, TooltipPosition } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import React, { Suspense, useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { Button, ButtonVariant } from "@components/button";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { type reportsTableFilters_Query } from "@relay/reportsTableFilters_Query.graphql";
import { QUERY } from "@screens/reports/parts/reports-table-filters/reports-table-filters.graphql";
import {
	wrapperClass,
	formContentWrapperClass,
	systemEmailWrapperClass,
} from "@screens/reports/parts/reports-table-filters/reports-table-filters.styles";
import { type ReportsTableFiltersFormState } from "@screens/reports/parts/reports-table-filters/reports-table-filters.types";
import { ReportsContext } from "@screens/reports/reports.context";

export const ReportsTableFilters = () => {
	const query = useLazyLoadQuery<reportsTableFilters_Query>(QUERY, {});
	const { filters, setFilters } = useContext(ReportsContext);

	const formik = useFormik<ReportsTableFiltersFormState>({
		initialValues: {
			...filters,
		},
		onSubmit: (values: ReportsTableFiltersFormState, { setSubmitting }) => {
			setFilters({ filterByReferenceNumberOpt: values.filterByReferenceNumberOpt });
			setSubmitting(false);
		},
	});

	const clearForm = () => {
		setFilters({});
		void formik.setFieldValue("filterByReferenceNumberOpt", "");
		formik.handleSubmit();
	};

	return (
		<Suspense fallback={"Loading..."}>
			<div className={wrapperClass}>
				<form onSubmit={formik.handleSubmit}>
					<div className={formContentWrapperClass}>
						<ValidatedField<ReportsTableFiltersFormState, string>
							name="filterByReferenceNumberOpt"
							label="Referenznummer"
							className="mr-2"
							component={DefaultTextFieldComponent}
							formikConfig={formik}
						/>
						<Button
							type={ButtonType.Reset}
							variant={ButtonVariant.Outline}
							tooltip="Filter zurücksetzen"
							onClick={clearForm}
							icon={EpicIcons.TIMES}
						/>
						<Button
							type={ButtonType.Submit}
							tooltip="Filter anwenden"
							icon={EpicIcons.SEARCH}
						/>
					</div>
				</form>

				<div className={systemEmailWrapperClass}>
					<Tooltip
						target="#report-system-email"
						position={TooltipPosition.Top}
						content="E-Mail an welche alle DSA-Reports gesendet werden."
					/>
					<div id="report-system-email">
						<Icon icon={EpicIcons.INFO_CIRCLE} />
					</div>
					<span>E-Mail:</span> {query.Viewer.Reports.SystemEmail}
				</div>
			</div>
		</Suspense>
	);
};
