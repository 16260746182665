/**
 * @generated SignedSource<<e3e00377e9a753c1ea81aba358a5b217>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DiscountCodeTable_DiscountCodeFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CodeActionColumn_DiscountCodeFragment" | "CodeCodeColumn_DiscountCodeFragment" | "CodeCreatedAtColumn_DiscountCodeFragment" | "CodeIdColumn_DiscountCodeFragment" | "CodeNumUsagesColumn_DiscountCodeFragment">;
  readonly " $fragmentType": "DiscountCodeTable_DiscountCodeFragment";
};
export type DiscountCodeTable_DiscountCodeFragment$key = {
  readonly " $data"?: DiscountCodeTable_DiscountCodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DiscountCodeTable_DiscountCodeFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "DiscountCodeTable_DiscountCodeFragment"
};

(node as any).hash = "6685ead3a24d5700eb4303a2f0adc695";

export default node;
