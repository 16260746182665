/**
 * @generated SignedSource<<a841e82cdc20a783883b3ec3a45198d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateRandomDiscountCodesInput = {
  clientMutationId?: string | null | undefined;
  discountActionId: string;
  number: number;
};
export type CreateRandomCodesButton_CreateMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateRandomDiscountCodesInput;
};
export type CreateRandomCodesButton_CreateMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly createRandomDiscountCodes: {
        readonly connection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly " $fragmentSpreads": FragmentRefs<"DiscountCodeTable_DiscountCodeFragment">;
            };
          } | null | undefined> | null | undefined;
        };
      } | null | undefined;
    };
  };
};
export type CreateRandomCodesButton_CreateMutation = {
  response: CreateRandomCodesButton_CreateMutation$data;
  variables: CreateRandomCodesButton_CreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateRandomCodesButton_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateRandomDiscountCodesPayload",
                "kind": "LinkedField",
                "name": "createRandomDiscountCodes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountCodesV2Connection",
                    "kind": "LinkedField",
                    "name": "connection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountCodesV2Edge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DiscountCode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "InlineDataFragmentSpread",
                                "name": "DiscountCodeTable_DiscountCodeFragment",
                                "selections": [
                                  {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "CodeIdColumn_DiscountCodeFragment"
                                  },
                                  {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "CodeCodeColumn_DiscountCodeFragment"
                                  },
                                  {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "CodeNumUsagesColumn_DiscountCodeFragment"
                                  },
                                  {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "CodeCreatedAtColumn_DiscountCodeFragment"
                                  },
                                  {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "CodeActionColumn_DiscountCodeFragment"
                                  }
                                ],
                                "args": null,
                                "argumentDefinitions": []
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateRandomCodesButton_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateRandomDiscountCodesPayload",
                "kind": "LinkedField",
                "name": "createRandomDiscountCodes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountCodesV2Connection",
                    "kind": "LinkedField",
                    "name": "connection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountCodesV2Edge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DiscountCode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "id",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "code",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "numUsages",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "createdAt",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "filters": null,
                        "handle": "prependEdge",
                        "key": "",
                        "kind": "LinkedHandle",
                        "name": "edges",
                        "handleArgs": [
                          {
                            "kind": "Variable",
                            "name": "connections",
                            "variableName": "connections"
                          }
                        ]
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d2f5800a4aea91349bd45188f642a009",
    "id": null,
    "metadata": {},
    "name": "CreateRandomCodesButton_CreateMutation",
    "operationKind": "mutation",
    "text": "mutation CreateRandomCodesButton_CreateMutation(\n  $input: CreateRandomDiscountCodesInput!\n) {\n  Admin {\n    Billing {\n      createRandomDiscountCodes(input: $input) {\n        connection {\n          edges {\n            node {\n              ...DiscountCodeTable_DiscountCodeFragment\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment CodeActionColumn_DiscountCodeFragment on DiscountCode {\n  id\n}\n\nfragment CodeCodeColumn_DiscountCodeFragment on DiscountCode {\n  code\n}\n\nfragment CodeCreatedAtColumn_DiscountCodeFragment on DiscountCode {\n  createdAt\n}\n\nfragment CodeIdColumn_DiscountCodeFragment on DiscountCode {\n  id\n}\n\nfragment CodeNumUsagesColumn_DiscountCodeFragment on DiscountCode {\n  numUsages\n}\n\nfragment DiscountCodeTable_DiscountCodeFragment on DiscountCode {\n  ...CodeIdColumn_DiscountCodeFragment\n  ...CodeCodeColumn_DiscountCodeFragment\n  ...CodeNumUsagesColumn_DiscountCodeFragment\n  ...CodeCreatedAtColumn_DiscountCodeFragment\n  ...CodeActionColumn_DiscountCodeFragment\n}\n"
  }
};
})();

(node as any).hash = "a68a1d3ec03b15a6a4c42f7179d36515";

export default node;
