import { graphql, useFragment } from "react-relay";
import React from "react";
import { type CodeActionColumn_DiscountCodeFragment$key } from "@relay/CodeActionColumn_DiscountCodeFragment.graphql";
import { EditCodeButton } from "./EditCodeButton";
import { DeleteDiscountCodeButton } from "../../../features/discount-codes/DeleteDiscountCodeButton";

const DISCOUNT_CODE_FRAGMENT = graphql`
	fragment CodeActionColumn_DiscountCodeFragment on DiscountCode {
		id
	}
`;

type OwnProps = {
	discountActionId: string;
	connectionId: string;
	discountCodeFragmentRef: CodeActionColumn_DiscountCodeFragment$key;
};

export const CodeActionColumn = ({
	discountActionId,
	connectionId,
	discountCodeFragmentRef,
}: OwnProps) => {
	const discountCode = useFragment<CodeActionColumn_DiscountCodeFragment$key>(
		DISCOUNT_CODE_FRAGMENT,
		discountCodeFragmentRef,
	);

	return (
		<div>
			<EditCodeButton discountActionId={discountActionId} codeId={discountCode.id} />
			<DeleteDiscountCodeButton
				className="ml-2"
				id={discountCode.id}
				connectionId={connectionId}
			/>
		</div>
	);
};
