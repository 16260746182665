/**
 * @generated SignedSource<<fe8b23f24923276f06fa1974c7997313>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type LicenseDefinitionDataKind = "AllTrees" | "Tree" | "Trees";
import { FragmentRefs } from "relay-runtime";
export type licenseDefinitionsTable_LicenseDefinitionsFragment$data = {
  readonly Admin: {
    readonly LicenseDefinition: {
      readonly SearchLicenseDefinitions: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly data: {
              readonly kind: LicenseDefinitionDataKind;
              readonly name: string;
            };
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"editTreeLicenseDefinitionButton_LicenseDefinitionFragment">;
          };
        } | null | undefined> | null | undefined;
      };
    };
  };
  readonly " $fragmentType": "licenseDefinitionsTable_LicenseDefinitionsFragment";
};
export type licenseDefinitionsTable_LicenseDefinitionsFragment$key = {
  readonly " $data"?: licenseDefinitionsTable_LicenseDefinitionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licenseDefinitionsTable_LicenseDefinitionsFragment">;
};

import LicenseDefinitionsTable_Refetch_graphql from './LicenseDefinitionsTable_Refetch.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "Admin",
  "LicenseDefinition",
  "SearchLicenseDefinitions"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dataKinds"
    },
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "nameMatchRegex"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": LicenseDefinitionsTable_Refetch_graphql
    }
  },
  "name": "licenseDefinitionsTable_LicenseDefinitionsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LicenseDefinitionAdminSchema",
          "kind": "LinkedField",
          "name": "LicenseDefinition",
          "plural": false,
          "selections": [
            {
              "alias": "SearchLicenseDefinitions",
              "args": [
                {
                  "kind": "Variable",
                  "name": "dataKinds",
                  "variableName": "dataKinds"
                },
                {
                  "kind": "Variable",
                  "name": "nameMatchRegex",
                  "variableName": "nameMatchRegex"
                }
              ],
              "concreteType": "LicenseDefinitionsConnection",
              "kind": "LinkedField",
              "name": "__LicenseDefinitionsTable_SearchLicenseDefinitions_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LicenseDefinitionsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LicenseDefinition",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "data",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "name",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "kind",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "editTreeLicenseDefinitionButton_LicenseDefinitionFragment"
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__id",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "8327e8ee4d85e0af7aaac650349c23ff";

export default node;
