import { graphql } from "react-relay";

export const QUERY = graphql`
	query licenseDefinitionsTable_Query($first: Int) {
		...licenseDefinitionsTable_LicenseDefinitionsFragment
			@arguments(first: $first, dataKinds: [Tree])
	}
`;

export const LICENSE_DEFINITIONS_FRAGMENT = graphql`
	fragment licenseDefinitionsTable_LicenseDefinitionsFragment on Query
	@refetchable(queryName: "LicenseDefinitionsTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		nameMatchRegex: { type: "String" }
		dataKinds: { type: "[LicenseDefinitionDataKind!]!" }
	) {
		Admin {
			LicenseDefinition {
				SearchLicenseDefinitions(
					first: $first
					after: $after
					nameMatchRegex: $nameMatchRegex
					dataKinds: $dataKinds
				) @connection(key: "LicenseDefinitionsTable_SearchLicenseDefinitions") {
					__id
					edges {
						node {
							id
							data {
								name
								kind
							}
							...editTreeLicenseDefinitionButton_LicenseDefinitionFragment
						}
					}
				}
			}
		}
	}
`;

export const DELETE_LICENSE_DEFINITION_MUTATION = graphql`
	mutation licenseDefinitionsTable_DeleteLicenseDefinitionAdminMutation(
		$input: DeleteLicenseDefinitionInput!
		$connections: [ID!]!
	) {
		Admin {
			LicenseDefinition {
				deleteLicenseDefinition(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
